import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const K_WIDTH = 40;
const K_HEIGHT = 40;

const styles = theme => ({
  marker: {
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    backgroundColor: theme.palette.text.green,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    position: 'absolute',
    zIndex: -1,
    width: '0px',
    height: '0px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: `20px solid ${theme.palette.text.green}`,
    bottom: '-6px',
  },
});

function Map(props) {
  const { lat, lng, classes } = props;
  const defaultProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 15,
  };
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <div lat={lat} lng={lng} className={classes.marker}>
          O
          <div className={classes.arrow} />
        </div>
      </GoogleMapReact>
    </div>
  );
}

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Map);
